import React from 'react';
import Home from "../components/Home";

import "../scss/index.scss";


const App = () => {
	return(
		<>
			<Home />
		</>
	);
}

export default App;
